html {
    box-sizing: border-box;
    line-height: 1.6;
    letter-spacing: 0.06em;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'IM Fell English', serif;
    text-shadow: 2px 2px 1px rgba(250, 250, 250, 0.144);
    font-display: auto;
    font-size: 1rem;
    line-height: 1.54;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga", "tnum", "case", "calt", "zero", "ss01", "locl";
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    // Default
    background-color: $light-background;
    color: $light-color;
    @media #{$media-size-phone} {
        font-size: 1rem;
    }
    @media (prefers-color-scheme: dark) {
        background-color: $dark-background;
        color: $dark-color;
    }
    @media (prefers-color-scheme: light) {
        background-color: $light-background;
        color: $light-color;
    }
    [data-theme=dark] & {
        background-color: $dark-background;
        color: $dark-color;
    }
    [data-theme=light] & {
        background-color: $light-background;
        color: $light-color;
    }
}

h2,
h3,
h4,
h5,
h6 {
    display: flex;
    align-items: center;
    line-height: 1.3;
}

h1 {
    font-size: 2.625rem;
}

h2 {
    font-size: 1.625rem;
    margin-top: 2.5em;
}

h3 {
    font-size: 1.375rem;
}

h4 {
    font-size: 1.125rem;
}

@media #{$media-size-phone} {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.4rem;
    }
    h3 {
        font-size: 1.15rem;
    }
    h4 {
        font-size: 1.125rem;
    }
}

a {
    color: inherit;
}

img {
    display: block;
    max-width: 100%;
    &.left {
        margin-right: auto;
    }
    &.center {
        margin-left: auto;
        margin-right: auto;
    }
    &.right {
        margin-left: auto;
    }
    &.circle {
        border-radius: 50%;
        max-width: 25%;
        margin: auto;
    }
}

figure {
    display: table;
    max-width: 100%;
    margin: 25px 0;
    &.left {
        margin-right: auto;
    }
    &.left-floated {
        margin-right: auto;
        float: left;
        img {
            margin: 20px 20px 20px 0;
        }
    }
    &.center {
        margin-left: auto;
        margin-right: auto;
    }
    &.right {
        margin-left: auto;
    }
    &.right-floated {
        margin-left: auto;
        float: right;
        img {
            margin: 20px 0 20px 20px;
        }
    }
    &.rounded {
        img {
            border-radius: 50%;
        }
    }
    figcaption {
        font-size: 14px;
        margin-top: 5px;
        opacity: 0.8;
        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
    }
}

em,
i,
strong {
    // Default
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
    @media (prefers-color-scheme: light) {
        color: black;
    }
    [data-theme=dark] & {
        color: white;
    }
    [data-theme=light] & {
        color: black;
    }
}

code {
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    font-display: auto;
    font-feature-settings: normal;
    padding: 1px 6px;
    margin: 0 2px;
    border-radius: 5px;
    font-size: 0.95rem;
    // Default
    background: $light-background-secondary;
    @media (prefers-color-scheme: dark) {
        background: $dark-background-secondary;
    }
    @media (prefers-color-scheme: light) {
        background: $light-background-secondary;
    }
    [data-theme=dark] & {
        background: $dark-background-secondary;
    }
    [data-theme=light] & {
        background: $light-background-secondary;
    }
}

pre {
    [data-theme=dark] & {
        background-color: $dark-background-secondary;
    }
    [data-theme=light] & {
        background-color: $light-background-secondary;
    }
    padding: 10px 10px 10px 20px;
    border-radius: 8px;
    font-size: 0.95rem;
    overflow: auto;
    @media #{$media-size-phone} {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    code {
        background: none !important;
        margin: 0;
        padding: 0;
        font-size: inherit;
        // Default
        color: #ccc;
        @media (prefers-color-scheme: dark) {
            color: inherit;
        }
        @media (prefers-color-scheme: light) {
            color: #ccc;
        }
        [data-theme=dark] & {
            color: inherit;
        }
        [data-theme=light] & {
            color: #ccc;
        }
    }
}

blockquote {
    border-left: 3px solid #3eb0ef;
    margin: 40px;
    padding: 10px 20px;
    @media #{$media-size-phone} {
        margin: 10px;
        padding: 10px;
    }
    &:before {
        content: "”";
        font-family: Georgia, serif;
        text-shadow: 2px 2px 1px rgba(250, 250, 250, 0.144);
        font-display: auto;
        font-size: 3.875rem;
        position: absolute;
        left: -40px;
        top: -20px;
    }
    p:first-of-type {
        margin-top: 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

ul,
ol {
    margin-left: 40px;
    padding: 0;
    @media #{$media-size-phone} {
        margin-left: 20px;
    }
}

ol ol {
    list-style-type: lower-alpha;
}

.container {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
    margin: 0;
    @media #{$media-size-phone} {
        margin-top: 0;
    }
}

hr {
    width: 100%;
    border: none;
    height: 1px;
    // Default
    background: $light-border-color;
    @media (prefers-color-scheme: dark) {
        background: $dark-border-color;
    }
    @media (prefers-color-scheme: light) {
        background: $light-border-color;
    }
    [data-theme=dark] & {
        background: $dark-border-color;
    }
    [data-theme=light] & {
        background: $light-border-color;
    }
}

.hidden {
    display: none;
}

.hide-on-phone {
    @media #{$media-size-phone} {
        display: none;
    }
}

.hide-on-tablet {
    @media #{$media-size-tablet} {
        display: none;
    }
}

// Accessibility
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    width: auto;
    top: 5px;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    z-index: 100000;
}

.background-image {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
}

// Prism JS Additionals
.highlight {
    margin: 30px auto;
}